.p-card-bg {
    background: rgb(36, 36, 36);
    margin: 0 .5%;
    border-radius: 20px;
    text-align: center;
    width: 25%;
    transition: width 300ms;
    font-size: 1em;
    cursor: pointer;
    transition: width 400ms;    
}


.p-card-title {
    color: rgb(187, 187, 187);
    text-align: center;
    margin-top: 1%;
    font-size: 1.25vw;
    font-family: 'Noto Serif', serif;
    animation-name: fadeIn; 
    animation-duration: 1s;
}

.p-card-image {
    width: 20vw;
    height: 32vw;
    object-fit: cover;
    object-position: top;
    box-shadow: 0px 0px 10px white;
    border-radius: 20px;
    transition: filter 200ms;
}

.p-card-stack {
    font-size: .75vw;
    color: rgb(187, 187, 187);
    text-align: center;
    animation-name: fadeIn; 
    animation-duration: 2s;
}

.p-card-bg a {
    color: white;
    text-decoration: none;
}

.modal-hidden {
    display: none;
    transition: all 3sec;
}

.modal-showing {
    display: block;
}

.title-hidden {
    display: none;
}

.stack-hidden {
    display: none;
}

.smaller {
    width: 40%;
    margin: 5% 0%;
    animation-name: fadeIn; 
    animation-duration: 3s;
    box-shadow: 0px 0px 10px white;
}

.open {
    transition: width 200ms;
    width: 30%;
}

@media screen and (max-width:965px){

    .p-card-bg {
        background: black;
        box-shadow: 0px 0px 10px white;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 10px 0;
    }

    .p-card-bg:hover {
        width: 100%;
    }

    .p-card-title {
        display: block;
        color: rgb(187, 187, 187);
        text-align: center;
        margin-top: 1%;
        font-size: 6vw;
        font-family: 'Noto Serif', serif;
        animation-name: fadeIn; 
        animation-duration: 0s;
    }
    
    .p-card-stack {
        display: block;
        font-size: 3.5vw;
        color: rgb(187, 187, 187);
        text-align: center;
        animation-name: fadeIn; 
        animation-duration: 0s;
    }

    .p-card-image {
        width: 80%;
        padding: 5% 0;  
        border: none;
        border-radius: 0;
    }

    .p-card-image:hover {
        filter: brightness(1);
        width: 80%;
    }

    .smaller {
        width: 100%;
    }

    .modal-hidden {
        display: block;
    }

    .smaller {
    animation-name: fadeIn; 
    animation-duration: 0s;
    }

    .title-hidden {
        display: block;
        color: rgb(187, 187, 187);
        text-align: center;
        margin-top: 1%;
        font-size: 6vw;
        font-family: 'Noto Serif', serif;
        animation-name: fadeIn; 
        animation-duration: 0s;
    }

    .stack-hidden {
        display: block;
        font-size: 3.5vw;
        color: rgb(187, 187, 187);
        text-align: center;
        animation-name: fadeIn; 
        animation-duration: 0s;
    }

}
