@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200&family=Noto+Serif&display=swap');

.contact-page-container {
    padding: 100px 0 400px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background: rgb(36, 36, 36);
    border-bottom:rgb(48, 48, 48) 2px solid;
} 

.contact-title {
    font-size: 3vw;
    margin-bottom: 2%;
    font-family: 'Cairo', sans-serif;;
}

@media screen and (max-width:965px){
    .contact-title {
        font-size: 7vw;
    }
}