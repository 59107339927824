.album-container {
    width: 45%;
    display: flex;
    border: 1px solid white;
    border-radius: 20px;
    background: black;
    color: rgba(255, 255, 255, 0.754);
    padding: 1%;
    margin: 1%;
}


.album-container a{
    color: rgb(255, 255, 255);
    text-decoration: none;
    transition: color .5s;
}
.album-container a:hover{
    color: rgb(246, 121, 121);
    text-decoration: none;
}

.album-art {
    width: 30%;
    box-shadow: 0 0 10px white;
}

.album-info {
    flex-direction: column;
    margin-left: 20px;
}

.album-artist {
    font-size: 2vw;
}

.sub-title {
    font-size: 1vw;
    font-style: italic;
}

.album-release {
    font-size: 1vw;
}

.album-project {
    font-size: 1.25vw;
}

.album-credit {
    font-size: 1.5vw;
}

@media screen and (max-width:965px){
    .album-container {
        width: 95%;
        padding: 2.5%;
    }

    .album-art {
        width: 30%;
        box-shadow: 0 0 10px white;
    }
    
    .album-artist {
        font-size: 5vw;
    }

    .sub-title {
        font-size: 2.5vw;
    }
    
    .album-project {
        font-size: 3vw;
    }
    
    .album-release {
        font-size: 2vw;
    }
    
    .album-credit {
        font-size: 3vw;
    }
}