.home-page-container {
    background: rgb(32, 32, 32);
}

.home-video-low-audio {
    margin-top: -20%;
    object-fit: cover;
    object-position: 0 100px;
    width: 100%;
    box-shadow: 0px 0px 10px white;
  }

.home-video-low-dev {
    margin-top: -20%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 10px white;
  }

  .hide-video {
    display: none;
  }

@media screen and (max-width: 965px) {
    .home-video-low-dev {
      margin-top: -10%;
    }

    .home-video-low-audio {
      margin-top: -10%;
      object-position: 0 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-shadow: 0px 0px 10px white;
    }


}