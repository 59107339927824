.about-page-container {
    padding: 7% 0 12% 0;
    display: flex;
    align-items: top;
    justify-content: space-evenly;
    color: white;
    background: rgb(36, 36, 36);
    border-bottom:rgb(48, 48, 48) 2px solid;
}
.about-page-container p {
    font-size: 1;
}
.about-page-container h1 {
    font-size: 1.5vw;
    font-style: italic;
}

.bio-btn {
    background: none;
    margin: 5%;
    color: white;
    font-size: 1.5vw;
    font-weight: bold;
    border: none;
    filter: brightness(.5);
    cursor: pointer;
    transition: filter 300ms
}

.bio-btn:hover{
    filter:brightness(.75); 
}

.bio-btn-active {
    filter: brightness(1);
}

.bio-secret-btn {
    cursor: pointer;
}

.about-image {
    width: 45%;
    margin-right: 60px;
    box-shadow: 0px 0px 10px white;
    border-radius: 20px;
}

.bio-descriptions{
    width: auto;
    font-size: 1vw;
    margin-left: 60px;
    margin-right: 20px;
    line-height: 1.5;
}
.emphasize {
    font-style: italic;
    font-weight: bold;
}

.about-icons-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
}

.about-icons-container a {
    color: grey;
    transition: color 300ms
}
.about-icons-container a:hover {
    color: rgb(255, 255, 255);
}

.about-logos {
    margin: 0 25px;
}

 .show {
    display: block;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }


.hide {
    display: none;
    transition: all 1s ease;
}

@media screen and (max-width:965px){
    .about-page-container{
        flex-direction: column-reverse;
    }

    .about-icons-container {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .btn-container {
        width:auto;
        text-align: center;
    }

    .bio-btn {
        margin: 5% 2%;
    }

    .about-page-container h1 {
        font-size: 2em;
        text-decoration: underline;
    }

    .bio-descriptions{
        width: 90%;
        align-self: center;
        margin: 0;
        font-size: 1em;
    }

    .about-image{
        align-self: center;
        width: 80%;
        margin-right: 0;
    }

    .bio-btn {
        font-size: 3vw;   
    }

    .bio-btn:hover{
        filter:brightness(1); 
    }
}

