.modal-container {
    height: 0;
}

.modal-container.active{
    height: auto;
    margin-bottom: 10px;
    animation-name: fadeIn; 
    animation-duration: 2s;
}

.modal-description {
    text-align: center;
    font-size: .9vw;
    line-height: 1.15;
    margin: 2% 5%;
}

.modal-links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
}

.link-titles {
    margin-left: 10px;
    font-size: .85vw;
    color: red;
}

.link-titles:hover {
    color: rgb(180, 180, 180);
}


@media screen and (max-width:965px){
    .modal-description {
        text-align: center;
        font-size: 3.15vw;
        line-height: 1.15;
        margin: 2% 3%;
    }
    .modal-links-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 5px;
    }

    .modal-container.active{
        height: auto;
        margin-bottom: 10px;
        animation-name: fadeIn; 
        animation-duration: 0s;
    }
   
    .link-titles {
        font-size: 4vw;
    }

    .modal-container {
        height: auto;
    }

}