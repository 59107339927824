.credits-page-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background: rgb(36, 36, 36);
    border-bottom:rgb(48, 48, 48) 2px solid;
} 

.album-credits {
    background: rgb(36, 36, 36);
    color: white;
    margin: 0;
    padding-top: 40px;
    font-family: 'Cairo', sans-serif;
    font-size: 3vw;
    text-align: center;
}
.jbtv {
    display: flex;
    justify-content: center;
    font-size: 1.25vw;
    padding-top: 0;
}

.proj-btns{
    background: none;
    margin: 1.5%;
    color: white;
    font-size: 1.5vw;
    font-weight: bold;
    border: none;
    filter: brightness(.5);
    cursor: pointer;
    transition: filter 300ms
}

.proj-btns:hover{
    filter: brightness(.75);
}

.proj-btns-active{
    filter: brightness(1);
}
@media screen and (max-width:965px){
    .credits-page-container{
        flex-direction: column;
        align-items: center;
    }

    .proj-btn-container{
        justify-content: center;
    }

    .album-credits {
        font-size: 5.5vw;
    }

    .proj-btns{
        font-size: 3vw;
        margin: 10px .05% 10px .05%;
    }
    .proj-btns:hover{
        filter: brightness(1);
    }
}
