@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200&family=Noto+Serif&display=swap');

.navbar {
    background: rgb(36, 36, 36);
    height: 90px;
    color: white;
    border-bottom:rgb(129, 129, 129) 2px solid;
}

.nav-menu {
    display: flex;
    justify-content: right;
    top: 30px;
    width: 100%;
    position: absolute;
  }

.nav-links {
    color: white;
    font-size: 1.5em;
    display: flex;
    width: 60%;
    justify-content: right;    
    margin-right: 60px;
}


.nav-links a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    margin-right: 5%;
    cursor: pointer;
    filter:brightness(.5);
    transition: filter 400ms;
}
.nav-links a:hover {
    filter: brightness(.75);
}

#title {
    position: absolute;
    color: rgb(255, 255, 255);
    top: 5px;
    align-items: baseline;
    justify-content: left;
    cursor: pointer;
    margin-left: 20px;
    z-index: 99999;
}

#sub-title {
    position: absolute;
    top: 40px;
    width: 400px;
    margin-top: 5px;
}

#title a {
    text-decoration: none;
    color: #ffffff;
    font-family: 'Noto Serif', serif;
}

#title a:hover {
    filter: opacity(.5);
}

#title h1 {
    margin-right: 10px;
}

#title h4 {
    font-family: 'Cairo', Arial;
}

.sticky {
    position: sticky;
    top: 0;
    margin-right: 0;
    z-index: 99999;
}

.menu-icon {
    display: none;
}

a.active {
filter: brightness(1);
}

@media screen and (max-width: 965px) {
.navbar {
    height: 75px;
}

.menu-icon {
    display: flex;
    flex-direction: column;
    position: absolute;
    color: rgba(255, 255, 255, 0.517);
    top: 54px;
    right: 15px;
    font-size: 1.8rem;
    cursor: pointer;
}

.menu-dashes {
    margin: -20px 0 -20px 0;
    transition: all 50ms;
}

.menu-dashes-angle1{
    position: absolute;
    transform: rotate(225deg);
    top: -15px;
    right: 0px;
    margin: -20px 0 -20px 0;
    transition: all 500ms;
}

.menu-dashes-angle2{
    position: absolute;
    transform: rotate(-225deg);
    top: -15px;
    right: 0px;
    transition: all 500ms;
    margin: -20px 0 -20px 0;
    filter: opacity(.6);
}

#title h1 {
    font-size: 2em;
}

#sub-title {
    position: absolute;
    top: 40px;
    width: 300px;
    margin-top: 2px;
}

  #title h4 {
    font-size: 1em;
}

  .nav-menu {
    height: 0px;
    position: absolute;
    top: 70px;
    left: 0px;
    overflow: hidden;
  }

  .nav-menu.active {
    height: 165px;
    background: rgb(36, 36, 36);
    top: 70px;
    left: 0px;
    border-bottom: black 1px solid;
    border-bottom: rgba(255, 255, 255, 0.49) 4px solid;
    transition: height 500ms ease;

  }

  .nav-links {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      top: 5px;
      font-size: 25px;
  }

  .nav-item {
      padding-bottom: 3px;
  }


  #title {
     margin-left: 15px;
}
  .active {
     display: flex;
     flex-direction: column;
     align-items: left;
}

.hide {
    display: none;
}
}
