@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200&family=Noto+Serif&display=swap');

.project-page-container {
    color: white;
    padding: 0 20px 5% 20px;
    display: flex;
    justify-content: center;
    background: rgb(36, 36, 36);
    border-bottom:rgb(48, 48, 48) 2px solid;
}

.projects-title {
    background: rgb(36, 36, 36);
    color: white;
    margin: 0;
    padding-top: 40px;
    font-family: 'Cairo', sans-serif;
    font-size: 3vw;
    text-align: center;
}

.proj-btn-container{
    display: flex;
    justify-content: center;
    background: rgb(36, 36, 36);
    margin-top: -10px;
}

.proj-btns{
    background: none;
    margin: 1%;
    color: white;
    font-size: 1.5vw;
    font-weight: bold;
    border: none;
    filter: brightness(.5);
    cursor: pointer;
    transition: filter 300ms
}

.proj-btns:hover{
    filter: brightness(.75);
}

.proj-btns-active{
    filter: brightness(1);
}

@media screen and (max-width:965px){
    .project-page-container{
        flex-direction: column;
    }

    .projects-title {
        font-size: 5.5vw;
    }

    .proj-btns{
        font-size: 3vw;
    }

    .proj-btns:hover{
        filter: brightness(1);
    }
}
